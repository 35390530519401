/* eslint-disable */
import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import UserLeft from '../PersonUserLeft.vue'
import CheckInService from '../../../api/CheckInService.js'
import vDistpicker from 'v-distpicker'
import CutImg from '../CutImg.vue'

export default{
	name:'EditOrgUser',
	data(){
		return{
			userInfo:'',//用户信息
			userAvtor:'', //头像
			orgName:'',//机构名称
			orgContact:'',//联系电话
			orgContactName:'',//联系人
			cutImg: '',
			orgAddress:'',//地址
			orgIntro:'',//简介
		}
	},
	methods:{
		GetUserInfo(){ //获取用户信息
			var that = this
			CheckInService.GetUserOrgInfo(that.$UserID).then(res=>{
				if(res.Tag == 1){
					that.userInfo = res.Result
					that.userAvtor = that.userInfo.Avatar?that.userInfo.Avatar:''
					that.orgName = that.userInfo.UserName?that.userInfo.UserName:''
					that.orgContact = that.userInfo.ContactsTel?that.userInfo.ContactsTel:''
					that.orgContactName = that.userInfo.Contacts?that.userInfo.Contacts:''
					that.orgAddress = that.userInfo.Address?that.userInfo.Address:'',
					that.orgIntro = that.userInfo.Introduction?that.userInfo.Introduction:''
				}
			})
		},
		uploadImg(e, num) { //图片裁剪
			var _this = this;
			//上传图片 
			var file = e.target.files[0]
			_this.fileName = file.name;
			if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
				_this.$alert('图片类型必须是.gif,jpeg,jpg,png,bmp中的一种', '提示', {
					confirmButtonText: '确定'
				});
				return false
			}
			var reader = new FileReader();
			reader.onload = (e) => {
				let data;
				if (typeof e.target.result === 'object') {
					// 把Array Buffer转化为blob 如果是base64不需要 
					data = window.URL.createObjectURL(new Blob([e.target.result]))
				} else {
					data = e.target.result
				}
				if (num === 1) {
					_this.$refs.CutImgPop.option.img = data
					_this.$refs.CutImgPop.CutImgPopup = true;
				} else if (num === 2) {
					_this.$refs.CutImgPop.example2.img = data
					_this.$refs.CutImgPop.CutImgPopup = true;
				}
		
			}
			reader.readAsArrayBuffer(file);
			_this.$refs.CutImgPop.CutImgPopup = true;
		},
		uploadCutImg() { //上传图片
			var that = this
			CheckInService.UserAvator(that.cutImg.substring(that.cutImg.indexOf("base64") + 7), 'Avatar').then(
				res => {
					if (res.Tag == 1) {
						that.userAvtor = res.Message
					} else {
						that.$alert(res.Message, '提示', {
							confirmButtonText: '确定',
						});
					}
				})
		},
		GetAddress(data){ //选择地址
			this.orgAddress = data.province.value + data.city.value
		},
		SaveEdit(){ //保存
			var that = this
			if(that.orgName.trim().length > 12){
				$('#orgName').text("昵称最多12个字符")
				$('#orgContact').text("")
				$('#orgContactName').text("")
				$('#orgIntro').text("")
				return
			}else if(that.orgContact.trim().length > 12){
				$('#orgContact').text("联系人最多12个字符")
				$('#orgName').text("")
				$('#orgContactName').text("")
				$('#orgIntro').text("")
				return
			}else if(that.orgContactName && !(/^1[3456789]\d{9}$/.test(that.orgContactName.trim()))){
				$('#orgContactName').text("请输入正确格式手机号")
				$('#orgName').text("")
				$('#orgContact').text("")
				$('#orgIntro').text("")
				return
			}else if(that.orgIntro.trim().length > 200){
				$('#orgIntro').text("简介最多200个字符")
				$('#orgName').text("")
				$('#orgContact').text("")
				$('#orgContactName').text("")
				return
			}
			$('#orgName').text("")
			$('#orgContact').text("")
			$('#orgContactName').text("")
			$('#orgIntro').text("")
			CheckInService.UserOrgEdit(that.$UserID,that.orgName.trim(),that.orgContactName.trim(),that.orgContact.trim(),that.orgAddress,that.userAvtor,that.orgIntro.trim()).then(res=>{
				console.log(res)
				if(res.Tag == 1){
					that.$router.go(-1)
				}else{
					that.$alert('修改失败', '提示', {
						confirmButtonText: '确定'
					});
				}
			})
		},
		CancelEdit(){ //取消
			this.$router.go(-1)
		}
	},
	created(){
		this.GetUserInfo()
	},
	components:{
		'Header': Header,
		'Footer':Footer,
		'UserLeft':UserLeft,
		vDistpicker,
		'CutImg':CutImg
	}
}
